@import "../../../styles/theme";

.bookings-filter{
    font-family: 'Montserrat';
    .advanced-search{
        font-family: 'Montserrat';
        font-weight: bold;
        margin-bottom: 20px;
        .inverted{
            rotate: 180deg;
        }
    }
    

    .export-data-content{
        width: 100%;



        .export-button-dropdown{
            display: flex;
            justify-content: flex-end;

            

            button:first-child {
                border-radius: $app-border-radius 0 0 $app-border-radius;
            }

            button:last-child {
                border-radius: 0 $app-border-radius $app-border-radius 0;
            }
        }
    }
}
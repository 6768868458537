.contract-element__header{
    display: flex;    
    justify-content: space-between;
    align-items: center;

    .star-is-base{
        margin-left: 10px;
        
    }

    .contract-element__header__name{
        small{
            display: block;
            font-weight: lighter;
        }
    }

    .contract-element__header__actions{
        display: flex;
        align-items: end;
        flex-direction: column;
        justify-content: center;
        padding: 5px 0;

        .alert{
            margin-top: 5px;
            display: block;
            padding: 0;
            small{
                padding: 0;
            }
        }
    }
    
}

.contract-element{
    
    .contract-element__body{
        display: flex;
        flex-direction: row;

        .contract-element__body__item{
            flex: 1 1;
            padding: 10px;
            display: flex;
            flex-direction: column;

            &.actions{             
                text-align: right;
                
            }
            
            label{
                font-weight: bold;
                font-family: 'Montserrat', sans-serif;
            }

            button{
                margin-right: 5px;
            }
        }
    }
}
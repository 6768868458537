.mapping-configuration-component {

    margin-left: 100px;
    margin-right: 100px;
    font-family: Montserrat;


    h2 {
        font-family: Montserrat;
        font-weight: bold;

    }

    .button-group {
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-bottom: 20px;
    }


    .table {
        border-collapse: collapse;
        margin-top: 20px;
        font-family: Montserrat;  
    }

    .button-class {
        align-items: center; 
        font-family: Montserrat;
        width: 200px;
        margin-top: -1px;
        margin-left: 20px;
    }

    .button-container {
        display:flex;
        justify-content: center;

    }

    .button-import {
        align-items: center; 
        font-family: Montserrat;
        width: 200px;
        background-color:var(--secondary-app-color);
        border-radius: 0;
        margin-left : 20px;
    }




}
.contracts-page{
    padding: 1em 2em;
    .base-contract-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 50px 0;
        gap: 25px;
        
        .app-logo{
            flex: 1 1;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                max-width: 100%;
                max-height: 80px;
            }
        }

        .contract-base-resume{
            flex: 1 1;
            border: 3px solid #000;
            outline: 5px solid #f1cf50;

            .app-card{
                margin-bottom: 0 !important;
            }
        }
    }

    .contracts-list{
        .contract-list-header{
            display: flex;
            margin: 20px 0;

            .contract-list-filter{
                flex: 1 1;
            }

            .contract-list-actions{
                flex: 1 1;
                text-align: right;
            }
        }
        
        .contract-list-content{

        }

        .contract-list-footer{
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
        }
    }
}
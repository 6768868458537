.button-tab-mapping-component {

    h2 {
        font-family: Montserrat;
        font-weight: bold;
        margin-top: 50px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-bottom: 40px;
        flex-direction: row;

        @media (max-width: 760px) {
            flex-direction: column; /* Cambia la dirección a columna */
            align-items: center; /* Alinea los botones al centro */
            gap: 20px; /* Reduce el espacio entre botones */
            }
            
    }

}

@import '../../../styles/theme';

.news-page {
    padding: 20px 40px;
    .text {
        font-family: 'Montserrat' !important;
        margin-top: 8px;
        text-align: center;
      }

    .upload-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 330px;
          height: auto;
        }
      
        .delete-icon {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          cursor: pointer;
          font-size: 15px; // Ajusta el tamaño si es necesario

          @media screen and (min-width: 1200px) {
            font-size: 20px; // Ajusta el tamaño si es necesario
            
          }
        }
      
        .upload-placeholder {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          border-radius:  $app-border-radius;      
        }
      }
      

    .row-buttons {
        display: flex;
        justify-content: flex-end; /* Alínea todo el contenido de las columnas a la derecha */
        gap: 16px; /* Espacio entre columnas */
        
        .ant-col {
            display: flex;
            justify-content: flex-end; /* Alinea cada botón dentro de su columna a la derecha */
            align-items: center; /* Asegura que los botones estén verticalmente centrados */
        }
    }
    



    .checkbox-col {
     
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
  
    .date-picker-col {
        display: flex;
        flex-direction: column; // Asegura que los elementos estén apilados verticalmente
        justify-content: center; // Centra verticalmente los elementos
        align-items: center; // Centra horizontalmente los elementos
        width: 100%; // Asegura que ocupe todo el ancho disponible
        box-sizing: border-box; // Incluye padding y border en el cálculo del ancho
    
        .ant-form-item {
            width: 100%; // Ajusta el ancho al contenedor padre
            max-width: 100%; // Asegura que no exceda el ancho disponible
        }
    
        .ant-picker {
            width: 100%; // Asegura que el DatePicker ocupe todo el ancho
            max-width: 100%; // Evita que sobrepase el ancho
            margin: 0 auto; // Centra el DatePicker horizontalmente
        }
    }
    
    
    
    
    
    

      .checkbox-with-description {
        display: flex;
        flex-direction: column; // Alinea elementos en columna
        align-items: flex-start; // Alinea al inicio (izquierda en LTR)

        .description {
            margin-left: 20px;
            margin-top: 4px; // Espacio entre el checkbox y el texto adicional
            color: #6c757d; // Color de texto más claro (opcional)
            font-size: 0.875rem; // Tamaño de fuente más pequeño (opcional)
        }
    }


    .button-container {
        display: flex;
        justify-content: center; /* Centra horizontalmente */
        align-items: center; /* Alinea verticalmente */
        height: 100%; /* Asegura que el contenido ocupe toda la altura */
        text-align: center; /* Alinea el texto del botón si es necesario */
        margin-right: 10px; /* Espacio entre el botón y el borde */
    }
    

    .border-container {
        border: 1px solid #625e5e;
        padding: 16px;
        border-radius: $app-border-radius;
        margin-bottom: 16px;
        width: 100%; // Asegura que el contenedor ocupe todo el ancho disponible
        box-sizing: border-box; // Incluye padding y border en el ancho total
    }
    .container-buttons {
        padding: 16px;
    }
    

    .avatar-uploader {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: auto;
        height: auto;
        max-height: 350px;
        margin-top: 10px;
        font-size: 25px;
        font-weight: 300;
    }

   

    .paragraph-style {
        font-family: 'Montserrat' !important;
        line-height: 1.5;
        color: #625e5e;
        font-size:10px !important;

    }

    .image-recommendations {
        font-family: 'Montserrat' !important;
        display: flex ; 
        justify-content: left;
        justify-items : left;
        margin-top: 6px;
        color: grey;
        font-size: 10px;
    }

    .image-error {
        font-family: 'Montserrat' !important;
        display: flex;
        justify-content: left;
        margin-top: 6px;
        color: red;
        font-size:10px;
    }

    .container-right {
        display: flex;
        justify-content: flex-end;
        font-family: 'Montserrat' !important;
        gap: 3px;

        .featured-icon {
            font-size: 24px;
            color: #faad14;
            cursor: pointer;
            margin-top: 3px;
            margin-left: 10px;

            @media (max-width: 700px) {
                font-size: 16px; // Ajusta el tamaño según sea necesario
            }
        }

        .three-dots-icon {
            font-size: 24px;
            font-weight: bold;

            @media (max-width: 700px) {
                font-size: 16px; // Ajusta el tamaño según sea necesario
            }
        }
    }



    .back-link {
        cursor: pointer;
        display: inline-flex;
        margin-bottom: 30px;
        align-items: center;
        text-decoration: none;
        /* Asegura que el texto no tenga subrayado por defecto */
        color: inherit;
        /* Mantiene el color actual */
        transition: text-decoration 0.2s ease;
        /* Suaviza la transición */
    }

    .back-link:hover {
        text-decoration: underline;
        /* Añade subrayado al pasar el ratón */
    }

    .back-button {
        margin-right: 8px;
        /* Espaciado entre el ícono y el texto */
    }

    .app-title.large {
        &:not(.no-bordered)::after {
            content: none; // Evitar el subrayado
        }
    }


}
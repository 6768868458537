@import '../../../styles/theme';

.communications-page{
    padding: 20px 40px;


    .title{
        font-family: 'Montserrat';
        margin:0px;
    }
    .text {
        font-family: 'Montserrat';
        padding-bottom:12px;
    }
    .app-button{
        width: 100%;
    }

    .pagination-container{
        padding: 20px 0px;
    }
    .container {
        margin-top: 20px;
    }

}
.register-form-component {

    margin-top: 20px;
    margin-left: 100px;
    margin-right: 100px;
    font-family: Montserrat;

   h3 {
        text-transform: none !important;
        font-size: 18px;
        font-family: Montserrat;
    }

    .select-left {
        display: flex; // si lo quiero a la izquierda sería borrar ésta y la siguiente línea

    }

    .title-configuration {
        font-family: Montserrat;
    }

    
    .radio-content {
        flex: 1; /* El contenido del radio se expandirá para llenar el espacio */
    }
    
    text {
        font-family: Montserrat;
        font-weight: bold;   
        margin-bottom: 10px;
        margin-top:10px
    }


    @media screen and (max-width: 768px){
        padding: 20px 10px;
    }

    .app-card{
        .ant-card-head{
            @media screen and (max-width: 768px){
                padding: 0;
            }
        }
        
    }

}
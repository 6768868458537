.login-page {
    background-color: #E6E6E6;
    height: 100vh;
    max-height: 100vh;
    display: flex;

    .language-selector {
        position: fixed;
        max-width: 150px;
        top: 10px;
        right: 10px;
        .selector{
            max-width: 150px;
        }
    }

    .inline-language-selector{
        
    }

    .form-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        box-sizing: border-box;
        overflow: hidden;


        .welcome-title {
            color: #61758A;
            font-family: 'Montserrat';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 10px;
        }

        .app-logo {
            max-width: 250px;
        }

    }

    .footer-content{
        position: fixed;
        bottom: 0;
        width: 100vw;
        padding: 20px 10px;
        font-family: 'Montserrat';
        font-size: 11px;
        text-align: center;

    }
}
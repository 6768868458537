@import '../../../styles/theme';


.booking-detail-page{
    padding-bottom: 6em;

    .room-commission-detail{
        clear:both;
        display: block;
        font-size: 11px;
    }

    .explain-if-net-or-pvp{
        margin-top: 10px;
    }


    .resume{

        
        .booking-status{
            position: absolute;
            right: 0;

            .ant-tag{
                width: 100px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2em;
                padding: 0;
            }
        }

        .icon-cancelled-at{
            margin-left: 10px;
        }

        .hotel-name{
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0;
            margin-top: 0;
        }

        .hotel-address, .hotel-phone{
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 158.824% */
            margin: 0;
        }

        

        .booking-header-resume{
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; 
            margin-top: 10px;
            margin-bottom: 15px;
            
            display: flex;
            flex-direction: column;

            span{
                margin: 5px 0;
            }
        }

        .btn-actions{
            text-align: right;
            padding-bottom: 3em;

            .left-time-to-enable-send-button{
                font-family: Montserrat;
                display: block;
                font-size: 12px;
                margin-top: 3px;
            }
            
            button{
                margin-left: 10px;
            }
        }
    }

    .resume-link{
        border-bottom: 1px dashed $primary-client-color;
        cursor: pointer;
        color: $primary-client-color;
    }

    .resume, .rooms, .services, .total-resume{
        margin-bottom: 10px;
        border-radius: 2px;
        background: white;

        h4{
            margin-top: 0px;
            margin-bottom: 5px;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 29px; /* 161.111% */
        }

        .regime-name{
            margin-top: -10px;
            margin-bottom: 10px;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 29px;

        }

        .main-title{
            border-left: solid 2px $primary-client-color;
            padding: 5px 10px;
            margin-bottom: 30px;

            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }

        .content{
            padding-left: 70px;
            padding-right: 70px;

            @media (max-width: 767px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        
            @media (min-width: 768px) and (max-width: 1023px) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    .rooms{
        .room{
            padding-bottom: 10px;
            margin-bottom: 15px;

            &:not(:last-child) {
                border-bottom: solid 1px #b8b8b8;
            }

            .room-details{
                padding-left: 30px;
            }

            .room-image{
                background-color: rgba(190, 188, 188, 0.397);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 260px;
                height: 136px;
            
                @media (min-width: 0px) and (max-width: 1023px) {
                    margin-bottom: 15px;
                }
            }


            .occupation,
            .cancellation-policies{
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                cursor: pointer;
                display: flex;
                align-items: center;

                .plus-icon{
                    fill: $primary-client-color;
                    margin-right: 5px;
                }
            }

            .cancellation-policies-list,
            .occupation-list{
                list-style: none;
                padding: 0 25px;

                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;

                li{
                    margin-bottom: 5px;
                }

                &.hidden{
                    display: none;
                }

            }

            .room-price{
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px; /* 161.111% */

                @media (min-width: 0px) and (max-width: 1023px) {
                    text-align: right;
                    padding-right: 20px;
                }
            }
            
        }
    }

    .services{
        .service{
            padding-bottom: 10px;
            margin-bottom: 15px;


            &:not(:last-child) {
                border-bottom: solid 1px #b8b8b8;
            }

            .image{
                background-color: rgba(190, 188, 188, 0.397);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 260px;
                height: 136px;

                @media (min-width: 0px) and (max-width: 1023px) {
                    margin-bottom: 15px;
                }
            }

            .description{
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-left: 30px;

                h4{
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-bottom: 0;
                }
            }

            .service-price{
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px; /* 161.111% */
                
                @media (min-width: 0px) and (max-width: 1023px) {
                    text-align: right;
                    padding-right: 20px;
                }
            }
        }
    }

    .pms-locator{
        font-size: 1.2em;
        padding: 10px 0;
    }

    .total-resume{
        padding-bottom: 2em;

        .total-pvp-price,
        .total-net-price,
        .total-commission-price
        {
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 0px;
            margin-bottom: 5px;
        }

        .total-net-price{
            margin-top: 10px;
            font-size: 1.3em;
        }

        .agency-more-info{
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            cursor: pointer;
            display: flex;
            align-items: center;

            .plus-icon{
                fill: $primary-client-color;
                margin-right: 5px;
            }
        }


        .agency-more-info-content{
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-left: 30px;

            &.hidden{
                display: none;
            }
        }
    }

    .owner-data{
        .owner-name,
        .owner-phone,
        .owner-email{
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 0px;
            margin-bottom: 5px;
        }
    }
    
    .background-image-resume{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 206.359px;
    }

    .room-occupancy-resume{
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 29px;
    }

    .cancelation-policy{
        span{
            margin: 0 5px;
        }
    }
}
@import '../../../styles/theme';

.bookings-list{
    margin-top: 2em;
    font-family: 'Montserrat';

    .loading-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: x-large;
        span{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    
    .header-container{
        font-family: 'Montserrat';

        .booking-resume-header{
            display: flex;
            align-items: center;
            @media screen and (max-width: 768px){
                max-width: 100%;
                overflow: auto;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            span:first-child{
                div{
                    display: flex;
                }
            }
    
            span{
                padding: 0 10px;
    
                small{
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }

        .show-booking-detail{
            margin-left: 10px;
            font-size: 24px;
        }

        .locator-container{
            border: solid 1px;
            border-radius: $app-border-radius;
        }

        .status{
            border: solid 1px;
            border-radius: $app-border-radius;
            width: 150px;
            text-align: center;
            padding: 0 10px;



            &.status-0{
                color: #f0ad4e;
                border-color: #f0ad4e;
                color: #f0ad4e;
            }

            &.status-1{
                color: #337ab7;
                border-color: #337ab7;
                color: #337ab7;
            }

            &.status-2{
                color: green;
                border-color: green;
                color: green;
            }

            &.status-3{
                color: red;
                border-color: red;
                color: red;
            }

            &.status-4{
                color: rgb(192, 148, 148);
                border-color: rgb(192, 148, 148);
                color: rgb(192, 148, 148);
            }

            
        }
    }

    .app-image{
        max-height: 20px;
    }

    .booking-status-and-pricing{
        .pricing-info{
            height: 50px;

            flex: 1;
            font-family: 'Montserrat';
            border: solid 1px;
            border-width: 1px 0 0 0;
            border-color: #e0e0e0;
            padding: 5px 10px;
            gap: 100px;
            display: flex;
            justify-content: center;

            @media screen and (max-width: 768px){
                justify-content: space-between;
                width: 100%;
                flex-wrap: wrap;
                margin-top: 10px;
            }

            .integration-icon{
                padding-top: 10px;
                text-align: center;
                display: flex;
                flex-direction: column;
                margin-right: auto;

                label{
                    font-size: 18px;
                    font-weight: 600;
                }

                small{
                    font-weight: 400;
                }
            }

            .price{
                padding-top: 10px;
                text-align: center;
                display: flex;
                flex-direction: column;

                label{
                    font-size: 18px;
                    font-weight: 600;
                }

                small{
                    font-weight: 400;
                }
            }
        }
    }

    .app-card{
        .booking-info, .other-info{
            font-family: 'Montserrat';
            span{
                margin-bottom: 5px;
                display:block;
                small{
                    display: block;
                    padding-left: 18px;
                }
            }

            
        }

        .other-info{
            font-family: 'Montserrat';

            @media screen and (max-width: 768px){
                border-top: 1px solid #e0e0e0;
                padding-top: 10px;
                margin-top: 10px;
            }

            
        }

        &.card-header-0{
            .ant-card-head{
                border-radius: $app-border-radius $app-border-radius 0 0;
                background-color: rgba(233, 207, 94, 0.05);
            }
        }

        &.card-header-1{
            .ant-card-head{
                border-radius: $app-border-radius $app-border-radius 0 0;
                background-color: rgba(0, 25, 253, 0.05);
            }
        }

        &.card-header-2{
            .ant-card-head{
                border-radius: $app-border-radius $app-border-radius 0 0;
                background-color: rgba(41, 145, 41, 0.05);
            }
        }

        &.card-header-3{
            .ant-card-head{
                border-radius: $app-border-radius $app-border-radius 0 0;
                background-color: rgba(255, 0, 0, 0.05);
            }
        }

        &.card-header-4{
            .ant-card-head{
                border-radius: $app-border-radius $app-border-radius 0 0;
                background-color: rgba(121, 16, 16, 0.05);
            }
        }

        
    }
}
.accounts-list{

    .loading-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: x-large;
        span{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    
    .account-item{
        border: solid 1px; 
        margin-bottom: 5px;
    }

    .app-link{
        font-family: 'Courier New', Courier, monospace;
        text-decoration: none !important;
        margin-left: 5px;
        font-size: 18px;
    }

    .statistics-list{
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.account-asignation-page{
    padding: 20px 40px;

    .button-container{
        display: flex; /* Coloca los botones uno al lado del otro */
        justify-content: flex-end; /* Alinea los botones a la derecha */
        align-items:flex-end;
 
    }


    .app-button{
        font-family: 'Montserrat';
        

        @media screen and (max-width: 768px) {
            width: 100%;
            ;
                      
        }
    }
 

    p {
        font-family: 'Montserrat' !important;
        margin:0 !important;
        padding-right: 5px !important;
      
        
        @media screen and (max-width: 768px) {
            padding-right: 0px !important;
            padding-bottom: 20px !important;
            
        };
    }

    .title {
        font-family: 'Montserrat' ;
        margin:0px;
    }
    
}
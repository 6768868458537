@import '../../../styles/theme';

.account-users-list-component{
    margin-top: 2em;
    font-family: 'Montserrat';
    
    .alert{
        text-align: center;
    }
    
    .actions-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width: 768px){
            justify-content: flex-end;
            padding: 5px 0;
            flex: 1 1;
            width: 100%;
        }

        svg{
            transition: all 0.3s ease-in-out;
            &:hover{
                cursor: pointer;
                color: $secondary-client-color !important;
                fill: $secondary-client-color !important;
                path{
                    color: $secondary-client-color !important;
                    fill: $secondary-client-color !important;
                    transition: all 0.3s ease-in-out;
                }
            }
        }

    }

    .loading-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: x-large;
        span{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .header-container{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .title{
            font-size: 1.5em;
            font-weight: bold;
        }
        
    }

    .app-card{
        .info{
            display: flex;
            flex-direction: column;
            gap: 5px;

            span{
                small{
                    display: block;
                    padding-left: 20px;

                }
            }

            .with-padding{
                padding-left: 20px;

                small{
                    display: block;
                }
            }
        }
    }

    
    
}